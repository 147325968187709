<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <div class="header_section">
                    <h4 class="menu_header">Pesapal Settings</h4>
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <form @submit.prevent="createPesapalSettings">
                    <div class="row">
                      <div class="col-md-12">
                        <div v-if="errors ">
                          <div class="alert alert-danger">
                            <ul>
                              <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col-lg-6">
                        <div class="setting_header">
                          <h2>Pesapal Options</h2>
                        </div>
                        <div class="setting_content mt-4">

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="page_title">Pesapal Currency Name:</label>
                              <div class="col-sm-7">

                                <Multiselect class="form-control"   @change="getCountryInfo($event)"  v-model="currencySymbolOptions"
                                  :disabled="disabled" 
                                  :value="currencySymbolOptions" 
                                  mode="tags" 
                                  track-by="value" 
                                  label="name" 
                                  :close-on-select="true" 
                                  :searchable="true"
                                  :object="true"
                                  :resolve-on-load="false"
                                  :options="coptions" :custom-label="name"  placeholder="Select Currency">
                                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag is-user">
                                      {{ option.name }}
                                      <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                        <span class="multiselect-tag-remove-icon"></span>
                                      </span>
                                    </div>
                                  </template>
                                </Multiselect>

                                <!-- <select class="form-control" v-model="currencySymbol" @change="getCountryInfo($event)">
                                  <option v-for="country in countries" :key="country.code" :value="country.currency_name">{{ country.currency_name }}</option>
                                </select> -->
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="currency_symbol">Pesapal Currency Code:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" disabled v-model="currencyCode">
                                <!-- <select class="form-control" v-model="currencySymbol">
                                  <option v-for="(currencysymbl, cindex) in currencyCodes" :key="cindex" :value="currencysymbl.symbol">{{currencysymbl.symbol}}</option>
                                </select> -->
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="email"> Pesapal Api Setup:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input v-model="pesapalApiSetup" type="radio" :value="1" checked>&nbsp; Live </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input v-model="pesapalApiSetup" type="radio" name="optradio" :value="0"> &nbsp; Demo</label>
                              </div>
                            </div>
                          </div>
                          <!--  -->
                          <transition name="fade">
                            <div v-if="pesapalApiSetup">
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Live Consumer Key:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="liveConsumerKey" placeholder="Enter Consumer Key">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="lv_consumer_secret">Live Consumer Secret:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="liveConsumerSecret" placeholder="Enter Consumer Secret" name="email">
                                  </div>
                                </div>
                              </div>

                              <!-- additional -->
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Live App ID:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="liveAppID" placeholder="Enter Api APP ID" name="email">
                                  </div>
                                </div>
                              </div>

                        
                              <!-- end -->
                            </div>
                          </transition>
                          <!--  -->

                          <!--  -->
                          <transition name="fade">
                            <div v-if="!pesapalApiSetup">
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="dm_consumer_key">Demo Consumer Key:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="demoConsumerKey" placeholder="Enter Consumer Key">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Demo Consumer Secret:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="demoConsumerSecret" placeholder="Enter Consumer Secret" name="email">
                                  </div>
                                </div>
                              </div>

                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Demo App ID:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="demoAppID" placeholder="Enter Api App ID" name="email">
                                  </div>
                                </div>
                              </div>

                        
                            </div>
                            
                          </transition>
                          <!--  -->
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="pwd">Enable Separate Pesapal Keys Per Store:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="keyPerStore" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="keyPerStore" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp" v-if="keyPerStore">
                            <div class="row">
                              <div class="col-sm-5"></div>
                              <div class="col-sm-7">
                                <div>
                                  <strong class="text-warning">(Please ensure consumer key are set in each store)</strong> <br>
                                </div>
                                <div class="mt-2">
                                  <button type="button" class="btn payment_ipn btn-purple mr-1 mt-1" @click="updatePaymentIpn()"> <span v-if="ipnloading"> <i class="fa fa-spinner fa-spin"></i></span> Update Stores Payment IPN</button>
                                </div>
                                
                               
                              </div>
                            </div>
                           
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Enable Cash Payment:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="enableCash" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="enableCash" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Enable Pesapal 4 LetterCode:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="enableLetterCode" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="enableLetterCode" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Pesapal 4 Letter code:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="letterCode" placeholder="Pesapal 4 Letter code">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="setting_header">
                          <h2>Pesapal Mobile MPESA STK</h2>
                        </div>
                        <div class="setting_content mt-4">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="enable_mbl">Enable Pesapal Mobile:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="enablePesapalMobile" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="enablePesapalMobile" :value="0"> &nbsp; No</label>

                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type">Pesapal Mobile Api:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="MobileSetup" :value="1" checked>&nbsp; Live </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="MobileSetup" :value="0"> &nbsp; Demo</label>

                              </div>
                            </div>
                          </div>
                          <!--  -->
                          <transition name="fade">
                            <div v-if="MobileSetup">
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="lv_mbl_key">Live Pesapal Mobile API Key:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="liveMobileKey" placeholder="Enter API Key">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="lv_mbl_secret">Live Pesapal Mobile API Secret:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="liveMobileSecret" placeholder="Enter API Secret ">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </transition>
                          <!--  -->
                          <!--  -->
                          <transition name="fade">
                            <div v-if="!MobileSetup">
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="dm_mbl_key">Demo Pesapal Mobile API Key:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="demoMobileKey" placeholder="Enter API Key">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="dm_mbl_secret">Demo Pesapal Mobile API Secret:</label>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="demoMobileSecret" placeholder="Enter API Secret ">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </transition>
                          <!--  -->

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="pos_url">Pesapal Mobile Merchant GUID:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="merchantGuiid" placeholder="Enter Merchant GUID ">
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--  -->
                      <div class="col-md-6">
                        <div class="setting_header">
                          <h2>Post To Pos Options</h2>
                        </div>
                        <div class="setting_content mt-4">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="enable_post_to_po">Post to Pesapal POS:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="postToPOS" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="postToPOS" name="optradio" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Enable Post To All Orders: <br>
                                <small>(<i>post based on all payment status</i> )</small>
                              </label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="allowPostAll" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="allowPostAll" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>


                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Enable Post To Room:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="enablePostToRoom" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="enablePostToRoom" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="aut_posting">Allow Multi-Revenue center(tender ID):</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="multitender" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="multitender" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="aut_posting">Automatic Posting:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="autoPosting" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="autoPosting" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">

                            <!-- test pos -->
                            <div v-for="posconfig in posConfigs" :key="posconfig.id">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right">Enable {{posconfig.name}} <span v-if="posconfig.version"><small>({{ posconfig.version }})</small></span>:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline">
                                    <input type="checkbox" @click="showContent(posconfig.id)" :id="`pos_config_${posconfig.id}`" :checked="posContent[posconfig.id] == posconfig.id ? true : false">
                                  </label>
                                </div>

                              </div>

                              <div class="row pos_content_body" v-show="posContent[posconfig.id] == posconfig.id">

                                <div class="col-sm-12">
                                  <div class="form-group setting_form_grp">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="pos_post_type"> POS Post Type:</label>
                                      <div class="col-sm-7">
                                        <select class="form-control" v-model="postType[posconfig.id]">
                                          <option value="create">Create</option>
                                          <option value="post">Post</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="pos_post_type">Tender ID:</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="tenderId[posconfig.id]">
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="comments_pos">Comment Poscodes:</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="commentPoscode[posconfig.id]">
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp" v-if="posconfig.version =='res'">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="comments_pos">Comment Poscodes(drinks):</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="drinksPoscode[posconfig.id]">
                                      </div>
                                    </div>
                                  </div>
                                  

                                </div>
                              </div>
                            </div>

                            <!-- end pos -->

                            <!-- <div class="row">
                              <label class="control-label col-sm-5 text-right" for="enable_post_to_po">POS Post System :</label>
                              <div class="col-sm-7">
                                <label v-for="config in posConfigs" :key="config.id" class="radio-inline">
                                  <input type="radio" v-model="posPostSystems" :value="config.name">&nbsp; {{ config.name }} &nbsp;&nbsp;
                                </label> 
                              </div>
                            </div> -->
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="pos_post_type">Outlet Number:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="outletNo">
                              </div>
                            </div>
                          </div>

                        </div>
                        <!-- collection method -->
                        <div class="setting_header">
                          <h2>Pos collection method</h2>
                        </div>

                        <div class="setting_content mt-4">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <div class="col-sm-4">
                                <label for="">Select Channel</label>
                                <select class="form-control" id="" v-model="channelName">
                                  <option  v-for="channel in channels" :key="channel.channel_name" :value="channel.channel_name">{{channel.channel_name}}</option>
                                </select>
                              </div>
                              <div class="col-sm-4">
                                <label for="">Collection Name</label>
                                <input type="text" class="form-control" id="" v-model="collectionName">
                              </div>
                              <div class="col-sm-4">
                                <button type="button" class="btn btn-orange btn-lg mt-4" @click="addCollection()">Add Collection</button>
                              </div>
                            </div>

                            <div class="mt-4">
                              <div class="badge badge_review mr-2" v-for="collection in collectionMethod" :key="collection.channel_name">
                                <span class="float-right remove_icon" @click="removeCollection(collection)" aria-hidden="true">&times;</span> <br>
                                <strong>Channel: </strong>  {{ collection.channel_name }} <br>  <strong>Collection: </strong> {{ collection.collection_name }}
                              </div>
                            </div>
                            

                          </div>
                        </div>
                        <!-- end collection method -->

                        <div class="setting_header">
                          <h2>Third party settings</h2>
                        </div>
                        <div class="setting_content mt-4">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="enable_post_to_po">Enable One way Integration <small>(bolt)</small>:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="onewayIntegration" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="onewayIntegration" name="optradio" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="setting_header">
                          <h2>Support Emails</h2>

                        </div>
                        <div class="setting_content mt-4">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="delivery_notification_email">Delivery Notification Email:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="deliveryNotificationEmail" placeholder="Enter Delivery Notification Email">
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="support_phone">Support Phone:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="supportPhone" placeholder="Enter  Support Phone">
                              </div>
                            </div>
                          </div>
                          
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="support_email">Support Emails:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="supportEmail" placeholder="Enter  Support Emails ">
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="support_email">Audit Emails:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="auditEmails" placeholder="Enter  Audit Emails ">
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button type="submit" class="btn btn-orange btn-lg"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save Changes</span></button>
                      </div>
                    </div>
                    <!-- end row -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
// import { add } from 'date-fns/esm';
const toast = useToast();
export default {
  components: {
    Multiselect
  },
    data() {
        return {
            loading: false,
            channelName:'',
            collectionName:'',
            errors: false,
            errorMsg: [],
            MobileSetup: 1,
            pesapalApiSetup: 1,
            spinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes...",
            currencyCode: 'KES',
            currencySymbol: 'Kenya Shilling',
            keyPerStore: 0,
            liveConsumerKey: '',
            liveConsumerSecret: '',
            demoConsumerKey: '',
            demoConsumerSecret: '',
            enableLetterCode: 1,
            letterCode: '',
            enablePesapalMobile: 0,
            liveMobileKey: '',
            liveMobileSecret: '',
            demoMobileKey: '',
            demoMobileSecret: '',
            merchantGuiid: '',
            postToPOS: 1,
            posMerchantName: '',
            autoPosting: 1,
            postType: [],
            tenderId: [],
            posContent: [],
            collectionMethod: [],
            posURl: '',
            deliveryNotificationEmail: '',
            supportPhone: '',
            notificationId: '',
            currencyCodes: [{
                    'code': 'KES',
                    'symbol': 'Kenya Shilling'
                },
                {
                    'code': 'UGX',
                    'symbol': 'Uganda Shilling'
                },
                {
                    'code': 'TZS',
                    'symbol': 'Tanzanian Shilling'
                },
            ],
          
            supportEmail: '',
            enablePostToRoom: 0,
            posConfigs:[],
            addedPosSystems:[],
            outletNo: 1,
            commentPoscode:[],
            drinksPoscode:[],
            countries:[],
            onewayIntegration: 0,
            coptions: [],
            currencySymbolOptions:[],
            ipnloading:false,
            multitender:0,
            enableCash:0,
            allowPostAll:0,
            
        }
    },
    watch:{
      currencySymbolOptions(){
        this.currencySymbol = this.currencySymbolOptions[0] && this.currencySymbolOptions[0].value !=undefined ? this.currencySymbolOptions[0].value : ''
        this.getCountryInfo()
      }
    },
    mounted() {
        this.getPesapalSettings();
        this.getPosConfigs();
        this.getCountries();
    },
    methods: {
      getCountryInfo(){
        if(this.currencySymbol !=''){
          const country = this.countries.filter((x) => x.currency_name == this.currencySymbol)
          this.currencyCode = country[0].currency
        }
      },

      removeCollection(collection) {
        this.collectionMethod = this.collectionMethod.filter((x) => x.channel_name != collection.channel_name)
      },  
      
      async getCountries(){
        try {
          const config = {
              headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = `${this.dashBaseUrl}/all-countries`;
          const resp = await this.axios.get(url,config)
          if(resp.status == 200){
            this.countries = resp.data.data
            for(let c = 0; c < this.countries.length; c++){
              this.coptions.push({
                "value": this.countries[c].currency_name,
                "name": this.countries[c].currency_name
              })

            }
          }
        } catch (error) {
          console.log(error)
        }

      },
        showContent(id) {
            if (document.getElementById(`pos_config_${id}`).checked) {
                this.posContent[id] = id
                if (!this.addedPosSystems.includes(id)) {
                    this.addedPosSystems.push(id)
                    this.postType[id] = 'create';
                    this.tenderId[id] = 0;
                    this.commentPoscode[id] = '';
                    this.drinksPoscode[id] = ''
                }
            } else {
                this.posContent[id] = null
                if (this.addedPosSystems.includes(id)) {
                    this.addedPosSystems = this.addedPosSystems.filter((x) => x !== id);
                    this.postType[id] = '';
                    this.tenderId[id] = '';
                    this.commentPoscode[id] = '';
                    this.drinksPoscode[id] = ''
                }
            }
        },
        addCollection(){
          if(this.collectionMethod.filter((x) => x.collection_name == this.collectionName).length == 0){
            this.collectionMethod.push({
              'collection_name': this.collectionName,
              'channel_name': this.channelName,
            })
          }
        },
        async updatePaymentIpn(){
          try {
            this.ipnloading = true
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/update-stores-ipn/${this.merchantID}`;
            const resp = await this.axios.post(url,{},config)
            if(resp.status == 200){
              this.ipnloading = false
              toast.success(resp.data.data.pesapal_config_msg)
            }
          } catch (error) {
            this.ipnloading = false
          }
        },
        async getPosConfigs() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/pos-configs/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('status', 1);
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.posConfigs = response.data.data.data
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
            // 
        },
        async createPesapalSettings() {
            this.spinner = true;
            this.errors = false;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/create-pesapal-settings";
            const posConfig = [];
            for (let i = 0; i < this.addedPosSystems.length; i++) {
                posConfig.push({
                    'post_system': this.addedPosSystems[i],
                    'pos_type': this.postType[this.addedPosSystems[i]],
                    'tender_id': this.tenderId[this.addedPosSystems[i]],
                    'comment_poscode': this.commentPoscode[this.addedPosSystems[i]],
                    'drink_poscode': this.drinksPoscode[this.addedPosSystems[i]]
                })
            }
            const payload = {
                'merchant_id': parseInt(this.merchantID),
                'currency_code': this.currencyCode,
                'currency_symbol': this.currencySymbol,
                'api_setup': this.pesapalApiSetup,
                'key_per_store': this.keyPerStore,
                'live_consumer_key': this.liveConsumerKey,
                'live_consumer_secret': this.liveConsumerSecret,
                'demo_consumer_key': this.demoConsumerKey,
                'demo_consumer_secret': this.demoConsumerSecret,
                'enable_letter_code': this.enableLetterCode,
                'letter_code': this.letterCode,
                'enable_pesapal_mobile': this.enablePesapalMobile,
                'pesapal_mobile_setup': this.MobileSetup,
                'live_pesapal_mobile_key': this.liveMobileKey,
                'live_pesapal_mobile_secret': this.liveMobileSecret,
                'demo_pesapal_mobile_key': this.demoMobileKey,
                'demo_pesapal_mobile_secret': this.demoMobileSecret,
                'merchant_guuid': this.merchantGuiid,
                'post_to_pos': this.postToPOS,
                'pos_merchant_name': this.posMerchantName,
                'auto_posting': this.autoPosting,
                'pos_url': this.posURl,
                'delivery_notification_email': this.deliveryNotificationEmail,
                'support_phone': this.supportPhone,
                'support_emails': this.supportEmail,
                'enable_post_room': this.enablePostToRoom,
                'tender_id': this.tenderId,
                'pos_configs': posConfig,
                'outlet_no': this.outletNo,
                'enable_oneway': this.onewayIntegration,
                'is_multi_outlet': this.multitender,
                'enable_cash': this.enableCash,
                'collection_methods': this.collectionMethod,
                'allow_post_all':this.allowPostAll
            };
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    this.getPesapalSettings();
                    toast.success('settings changes was successfully updated');
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                } else if (error.response.status === 401) {
                    toast.error('session has expired');
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => this.spinner = false);
        },
        async getPesapalSettings() {
            this.loading = true;
            const loader = document.getElementById('main-loader');
            loader.style.display = 'block';
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/configs/" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200 && !Array.isArray(response.data.data.pesapal_settings)) {
                    this.currencyCode = response.data.data.pesapal_settings.currency_code ? response.data.data.pesapal_settings.currency_code : 'KES',
                        this.currencySymbol = response.data.data.pesapal_settings.currency_symbol ? response.data.data.pesapal_settings.currency_symbol : 'Kenya Shilling',
                        this.pesapalApiSetup = response.data.data.pesapal_settings.api_setup !== '' ? response.data.data.pesapal_settings.api_setup : 1,
                        this.keyPerStore = response.data.data.pesapal_settings.key_per_store !== '' ? response.data.data.pesapal_settings.key_per_store : 0,
                        this.liveConsumerKey = response.data.data.pesapal_settings.live_consumer_key ? response.data.data.pesapal_settings.live_consumer_key : '',
                        this.liveConsumerSecret = response.data.data.pesapal_settings.live_consumer_secret ? response.data.data.pesapal_settings.live_consumer_secret : '',
                        this.demoConsumerKey = response.data.data.pesapal_settings.demo_consumer_key ? response.data.data.pesapal_settings.demo_consumer_key : '',
                        this.demoConsumerSecret = response.data.data.pesapal_settings.demo_consumer_secret,
                        this.enableLetterCode = response.data.data.pesapal_settings.enable_letter_code !== '' ? response.data.data.pesapal_settings.enable_letter_code : 1,
                        this.letterCode = response.data.data.pesapal_settings.letter_code ? response.data.data.pesapal_settings.letter_code : '',
                        this.enablePesapalMobile = response.data.data.pesapal_settings.enable_pesapal_mobile !== '' ? response.data.data.pesapal_settings.enable_pesapal_mobile : 0,
                        this.MobileSetup = response.data.data.pesapal_settings.pesapal_mobile_setup !== '' ? response.data.data.pesapal_settings.pesapal_mobile_setup : 1,
                        this.liveMobileKey = response.data.data.pesapal_settings.live_pesapal_mobile_key ? response.data.data.pesapal_settings.live_pesapal_mobile_key : '',
                        this.liveMobileSecret = response.data.data.pesapal_settings.live_pesapal_mobile_secret ? response.data.data.pesapal_settings.live_pesapal_mobile_secret : '',
                        this.demoMobileKey = response.data.data.pesapal_settings.demo_pesapal_mobile_key ? response.data.data.pesapal_settings.demo_pesapal_mobile_key : '',
                        this.demoMobileSecret = response.data.data.pesapal_settings.demo_pesapal_mobile_secret ? response.data.data.pesapal_settings.demo_pesapal_mobile_secret : '',
                        this.merchantGuiid = response.data.data.pesapal_settings.merchant_guuid ? response.data.data.pesapal_settings.merchant_guuid : '',
                        this.postToPOS = response.data.data.pesapal_settings.post_to_pos !== '' ? response.data.data.pesapal_settings.post_to_pos : 1,
                        this.posMerchantName = response.data.data.pesapal_settings.pos_merchant_name ? response.data.data.pesapal_settings.pos_merchant_name : '',
                        this.autoPosting = response.data.data.pesapal_settings.auto_posting !=='' ? response.data.data.pesapal_settings.auto_posting : 1,
                        this.posURl = response.data.data.pesapal_settings.pos_url ? response.data.data.pesapal_settings.pos_url : '',
                        this.outletNo = response.data.data.pesapal_settings.outlet_no ? response.data.data.pesapal_settings.outlet_no : 1,
                        this.multitender = response.data.data.pesapal_settings.is_multi_outlet ? response.data.data.pesapal_settings.is_multi_outlet : 0
                        // this.postType = response.data.data.pesapal_settings.post_type?response.data.data.pesapal_settings.post_type:'create',
                        this.deliveryNotificationEmail = response.data.data.pesapal_settings.delivery_notification_email ? response.data.data.pesapal_settings.delivery_notification_email : '',
                        this.supportPhone = response.data.data.pesapal_settings.support_phone ? response.data.data.pesapal_settings.support_phone : '',
                        this.supportEmail = response.data.data.pesapal_settings.support_emails ? response.data.data.pesapal_settings.support_emails : '',
                        this.enablePostToRoom = response.data.data.pesapal_settings.enable_post_room !== '' ? response.data.data.pesapal_settings.enable_post_room : 0
                        this.onewayIntegration =  response.data.data.pesapal_settings.enable_oneway !=='' ? response.data.data.pesapal_settings.enable_oneway : 0
                        this.enableCash = response.data.data.pesapal_settings.enable_cash? response.data.data.pesapal_settings.enable_cash : 0
                        this.allowPostAll = response.data.data.pesapal_settings.post_all_orders ? response.data.data.pesapal_settings.post_all_orders  : 0 
                        if(response.data.data.pesapal_pos_configs){
                          const posSettings = response.data.data.pesapal_pos_configs
                          for(let pos= 0; pos < posSettings.length; pos++ ){
                            this.posContent[posSettings[pos].pos_systems] = posSettings[pos].pos_systems;
                            this.postType[posSettings[pos].pos_systems] = posSettings[pos].pos_type;
                            this.tenderId[posSettings[pos].pos_systems] = posSettings[pos].tender_id;
                            this.commentPoscode[posSettings[pos].pos_systems] =posSettings[pos].comment_poscode;
                            if (!this.addedPosSystems.includes(posSettings[pos].pos_systems)) {
                                  this.addedPosSystems.push(posSettings[pos].pos_systems)
                              }
                          }
                        }
                        const channelCollections =response.data.data.collection_method
                        for(let c = 0; c < channelCollections.length; c++){
                          this.collectionMethod.push({
                            'channel_name': channelCollections[c].channel_name,
                            'collection_name': channelCollections[c].collection_name,
                          })
                        }
                        if(this.currencySymbol){

                          this.currencySymbolOptions.push({
                            "value": this.currencySymbol,
                            "name": this.currencySymbol
                          })
                        }
                    // this.tenderId = response.data.data.pesapal_settings.tender_id
                    // 
                }
            }).catch((error) => {
                console.log('error =>', error)
                if (error.response && error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
                this.loading = false
                loader.style.display = 'none';
            });
        }
    },
    mixins: [ReusableDataMixins]
}
</script>
